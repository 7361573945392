import '../../static/css/reset.css'
import '../../static/css/wrapper.css'
import '../../static/css/header.css'
import '../../static/css/disclaimer.css'
import '../../static/css/breadcrumb.css'
import '../../static/css/footer.css'
import React from 'react'
import { Header } from '../components/Header'
import { Breadcrumb } from '../components/Breadcrumb'
import { Footer } from '../components/Footer'
import Seo from '../components/Seo'
import { url } from '../config/domain'

export default function Disclaimer({ location }: any): JSX.Element {
  const locatePath = location.pathname.split('/')

  return (
    <div>
      <div className="wrapper">
        <Seo
          title={'免責事項'}
          description={
            'KURORO BLOGを運営するための免責事項詳細ページです。KURORO BLOGは、「モノづくりから始まるエンジニア」をコンセプトに、プログラミングに関心を持ってもらうための情報共有サイトです。'
          }
          url={url + 'disclaimer/'}
          noIndex={true}
          contentType="article"
        />
        <Header location={locatePath} />

        <h1 className="disclaimer__h1">免責事項</h1>
        <section className="disclaimer__section">
          <p>
            本サイトにコンテンツを掲載するにあたって、その内容、機能等について細心の注意を払っておりますが、コンテンツの内容が正確であるかどうか、最新のものであるか等について保証をするものではなく、何らの責任を負うものではありません。
          </p>
          <p>
            また、通知することなく本サイトに掲載した情報の訂正、修正、追加、削除等をいつでも行うことができるものとします。
          </p>
          <p>
            最後に本サイト、またはコンテンツのご利用により、万一、ご利用者様に何らかの不都合や損害が発生したとしても、一切の責任を負いません。
          </p>
        </section>
        <Breadcrumb
          breadcrumb={[
            {
              link: '/',
              text: 'KURORO BLOG',
            },
            {
              link: '/disclaimer/',
              text: '免責事項',
            },
          ]}
        />

        <Footer />
      </div>
    </div>
  )
}
